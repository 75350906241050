import { Application } from "@hotwired/stimulus"
import Counter_controller from "./counter_controller";
import Sidebar_controller from "./sidebar_controller";

const application = Application.start()
application.register("counter", Counter_controller)
application.register("sidebar", Sidebar_controller)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
