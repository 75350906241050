// app/javascript/controllers/sidebar_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        if (this.dropdownTarget) {
            this.dropdownTarget.addEventListener('show.bs.dropdown', this.hideLastLi.bind(this));
            this.dropdownTarget.addEventListener('hide.bs.dropdown', this.showLastLi.bind(this));
        }
    }

    hideLastLi() {
        const lastLi = this.element.querySelector('.mt-auto');
        lastLi.style.display = 'none';
    }

    showLastLi() {
        const lastLi = this.element.querySelector('.mt-auto');
        lastLi.style.display = 'list-item';
    }
}
